import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./auth";
import appBarReducer from "./appbar";
import dashboardReducer from "./dashboard";
import usersReducer from "./users";
import userSubscriptionsReducer from "./user_subscriptions";
import subscriptionLogsReducer from "./subscription_logs";
import paymentPlansReducer from "./payment_plans";
import promoCodesReducer from "./promo_codes";
import faqsReducer from "./faqs";
import contactSupportsReducer from "./contact_supports";
import localesReducer from "./locales";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    appBar: appBarReducer,
    dashboard: dashboardReducer,
    users: usersReducer,
    userSubscriptions: userSubscriptionsReducer,
    subscriptionLogs: subscriptionLogsReducer,
    paymentPlans: paymentPlansReducer,
    promoCodes: promoCodesReducer,
    faqs: faqsReducer,
    contactSupports: contactSupportsReducer,
    locales: localesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
