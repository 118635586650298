function CLoader({ className }) {
  return (
    <div
      className={`flex items-center justify-center h-[100%] w-[100%] ${className}`}
    >
      <span className="loading loading-ring loading-lg"></span>
    </div>
  );
}

export default CLoader;
