const locales = {
  swedish: "sv",
  english: "en",
};

const subscriptionStatuses = {
  active: "active",
  cancelled: "cancelled",
};

const promoCodeStatuses = {
  active: "active",
  unactive: "unactive",
};

module.exports = {
  locales,
  subscriptionStatuses,
  promoCodeStatuses,
};
