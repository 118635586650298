import { createBrowserRouter } from "react-router-dom";

import SLogin from "../screens/login";
import SDashboard from "../screens/dashboard";
import SUsers from "../screens/users";
import SUserSubscriptions from "../screens/user_subscriptions";
import SSubscriptionLogs from "../screens/subscription_logs";
import SPaymentPlans from "../screens/payment_plans";
import SPromoCodes from "../screens/promo_codes";
import SFAQs from "../screens/faqs";
import SContactSupports from "../screens/contact_supports";
import SLocales from "../screens/locales";

const authRouter = createBrowserRouter([
  {
    path: "*",
    element: <SLogin />,
  },
  {
    path: "/login",
    element: <SLogin />,
  },
]);

const userRouter = createBrowserRouter([
  {
    path: "*",
    element: <SDashboard />,
  },
  {
    path: "/dashboard",
    element: <SDashboard />,
  },
  {
    path: "/users",
    element: <SUsers />,
  },
  {
    path: "/subscriptions",
    element: <SUserSubscriptions />,
  },
  {
    path: "/subscription_logs",
    element: <SSubscriptionLogs />,
  },
  {
    path: "/payment_plans",
    element: <SPaymentPlans />,
  },
  {
    path: "/promo_codes",
    element: <SPromoCodes />,
  },
  {
    path: "/faqs",
    element: <SFAQs />,
  },
  {
    path: "/contact_supports",
    element: <SContactSupports />,
  },
  {
    path: "/locales",
    element: <SLocales />,
  },
]);

export { authRouter, userRouter };
