import axios from "axios";
import toast from "react-hot-toast";
import localforage from "localforage";

import {
  AppResponse,
  AppUser,
  Dashboard,
  User,
  SubscriptionLog,
  PaymentPlan,
  PromoCode,
  FAQ,
  ContactSupport,
} from "./models";

const RequestTypes = {
  get: "get",
  post: "post",
  put: "put",
  delete: "delete",
};

// const serverURL = "http://192.168.175.116:4001";
// const serverURL = "https://qarlie-dev-server.umarbinayaz.com";
const serverURL = "https://server.qarlie.com";

async function login({ email, password }) {
  try {
    const url = `${serverURL}/auth/admins/login`;

    const headers = await _headers({ parseAppUser: false });

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { email, password },
    });

    if (res.success) {
      let appUser = AppUser.parse(res.data);
      return appUser;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function sync(appUser) {
  try {
    const url = `${serverURL}/auth/sync`;

    const headers = await _headers();
    headers["authorization"] = `Bearer ${appUser.token}`;

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
    });

    if (res.success) {
      let appUser = AppUser.parse(res.data);
      return appUser;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function getDashboard() {
  try {
    const url = `${serverURL}/admins/dashboard`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      const dashboard = Dashboard.parse(res.data);
      return dashboard;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function getUsers() {
  try {
    const url = `${serverURL}/admins/users`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      const users = res.data.map((_) => User.parse(_));
      return users;
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
}

async function updateUser({ id, is_active }) {
  try {
    const url = `${serverURL}/admins/users/update`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { id, is_active },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function getUserSubscriptions() {
  try {
    const url = `${serverURL}/admins/user_subscriptions`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      const users = res.data.map((_) => User.parse(_));
      return users;
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
}

async function getSubscriptionLogs() {
  try {
    const url = `${serverURL}/admins/subscription_logs`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      const subscriptionLogs = res.data.map((_) => SubscriptionLog.parse(_));
      return subscriptionLogs;
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
}

async function createPaymentPlan({
  title,
  original_price,
  discounted_price,
  months,
  free_months,
  is_best_value,
}) {
  try {
    const url = `${serverURL}/admins/payment_plans/create`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        title,
        original_price,
        discounted_price,
        months,
        free_months,
        is_best_value,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function getPaymentPlans() {
  try {
    const url = `${serverURL}/admins/payment_plans`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      const paymentPlans = res.data.map((_) => PaymentPlan.parse(_));
      return paymentPlans;
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
}

async function updatePaymentPlan({
  id,
  title,
  original_price,
  discounted_price,
  months,
  free_months,
  is_best_value,
}) {
  try {
    const url = `${serverURL}/admins/payment_plans/update`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        id,
        title,
        original_price,
        discounted_price,
        months,
        free_months,
        is_best_value,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function deletePaymentPlan({ id }) {
  try {
    const url = `${serverURL}/admins/payment_plans/delete`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        id,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function createPromoCode({ title, status, discount }) {
  try {
    const url = `${serverURL}/admins/promo_codes/create`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        title,
        status,
        discount,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function getPromoCodes() {
  try {
    const url = `${serverURL}/admins/promo_codes`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      const promoCodes = res.data.map((_) => PromoCode.parse(_));
      return promoCodes;
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
}

async function updatePromoCode({ id, title, status }) {
  try {
    const url = `${serverURL}/admins/promo_codes/update`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        id,
        title,
        status,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function deletePromoCode({ id }) {
  try {
    const url = `${serverURL}/admins/promo_codes/delete`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        id,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function createFAQ({ title, description, locale }) {
  try {
    const url = `${serverURL}/admins/faqs/create`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        title,
        description,
        locale,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function getFAQs() {
  try {
    const url = `${serverURL}/admins/faqs`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      const faqs = res.data.map((_) => FAQ.parse(_));
      return faqs;
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
}

async function updateFAQ({ id, title, description, locale }) {
  try {
    const url = `${serverURL}/admins/faqs/update`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        id,
        title,
        description,
        locale,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function deleteFAQ({ id }) {
  try {
    const url = `${serverURL}/admins/faqs/delete`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        id,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function getContactSupports() {
  try {
    const url = `${serverURL}/admins/contact_supports`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      const contactSupports = res.data.map((_) => ContactSupport.parse(_));
      return contactSupports;
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
}

async function getLocale(path) {
  try {
    const url = `${serverURL}/locales/${path}`;

    const headers = await _headers();

    const res = await axios({
      method: RequestTypes.get,
      url: url,
      headers: headers,
    });

    const data = res.data;

    return data;
  } catch (e) {
    return null;
  }
}

async function updateLocale({ locale_path, locale_text }) {
  try {
    const url = `${serverURL}/admins/locales/update`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        locale_path,
        locale_text,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function _request({ requestType, url, headers, body, orotund = true }) {
  let appResponse;

  try {
    const res = await axios({
      method: requestType,
      url: url,
      data: body,
      headers: headers,
    });

    const data = res.data;

    appResponse = AppResponse.parse(data);
  } catch (e) {
    appResponse = AppResponse.failure();
  }

  if (!appResponse.success && orotund) {
    toast.error(appResponse.errorMessage);
  }

  return appResponse;
}

async function _headers(props = { parseAppUser: true }) {
  const obj = {};

  obj["x-access-token"] = `Basic ${process.env.REACT_APP_X_ACCESS_TOKEN}`;

  if (props.parseAppUser) {
    const appUser = await localforage.getItem("appUser");

    if (appUser != null) {
      obj["authorization"] = `Bearer ${appUser.token}`;
    }
  }

  return obj;
}

export {
  login,
  sync,
  getDashboard,
  getUsers,
  updateUser,
  getUserSubscriptions,
  getSubscriptionLogs,
  createPaymentPlan,
  getPaymentPlans,
  updatePaymentPlan,
  deletePaymentPlan,
  createPromoCode,
  getPromoCodes,
  updatePromoCode,
  deletePromoCode,
  createFAQ,
  getFAQs,
  updateFAQ,
  deleteFAQ,
  getContactSupports,
  getLocale,
  updateLocale,
};
