import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as AppNetworkRest from "../utils/network_rest";

const initialState = {
  contactSupports: [],
};

const getContactSupports = createAsyncThunk(
  "contactSupports/getContactSupports",
  async (args, { getState }) => {
    try {
      const contactSupports = await AppNetworkRest.getContactSupports();
      return contactSupports;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

export const contactSupportsSlice = createSlice({
  name: "contactSupports",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getContactSupports.fulfilled, (state, action) => {
      if (action.payload) {
        state.contactSupports = action.payload;
      }
    });
  },
});

export { getContactSupports };

export default contactSupportsSlice.reducer;
