import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as AppNetworkRest from "../utils/network_rest";
import { nullValidator, validate } from "../utils/validators";
import toast from "react-hot-toast";

const initialState = {
  locale: null,
};

const getLocale = createAsyncThunk(
  "locales/getLocale",
  async (args, { getState }) => {
    try {
      const { path } = args;

      const valError = validate([() => nullValidator("Title", path)]);
      if (valError) {
        toast.error(valError);
        return;
      }

      const locale = await AppNetworkRest.getLocale(path);
      return locale;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

const updateLocale = createAsyncThunk(
  "locales/updateLocale",
  async (args, { getState }) => {
    try {
      const str = await AppNetworkRest.updateLocale(args);

      if (str) {
        toast.success(str);
        return true;
      }

      return false;
    } catch (e) {
      console.log(e, "e");
      return false;
    }
  }
);

export const localesSlice = createSlice({
  name: "locales",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getLocale.fulfilled, (state, action) => {
      if (action.payload) {
        state.locale = action.payload;
      }
    });
  },
});

export { getLocale, updateLocale };

export default localesSlice.reducer;
