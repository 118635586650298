import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as AppNetworkRest from "../utils/network_rest";
import {
  nullValidator,
  promoCodeStatusValidator,
  numberValidator,
  validate,
} from "../utils/validators";
import toast from "react-hot-toast";

const initialState = {
  promoCodes: [],
};

const createPromoCode = createAsyncThunk(
  "promoCodes/createPromoCode",
  async (args, { getState }) => {
    try {
      const { title, status, discount } = args;

      const valError = validate([
        () => nullValidator("Title", title),
        () => promoCodeStatusValidator("Status", status),
        () => numberValidator("Discount", discount, 100),
      ]);
      if (valError) {
        toast.error(valError);
        return false;
      }

      if (discount < 0) {
        toast.error("Discount should be in range 5-80");
        return;
      }

      const str = await AppNetworkRest.createPromoCode(args);

      if (str) {
        toast.success(str);
        return true;
      }

      return false;
    } catch (e) {
      console.log(e, "e");
      return false;
    }
  }
);

const getPromoCodes = createAsyncThunk(
  "promoCodes/getPromoCodes",
  async (args, { getState }) => {
    try {
      const promoCodes = await AppNetworkRest.getPromoCodes();
      return promoCodes;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

const updatePromoCode = createAsyncThunk(
  "promoCodes/updatePromoCode",
  async (args, { getState }) => {
    try {
      const { id, title, status } = args;

      const valError = validate([
        () => nullValidator("Title", title),
        () => promoCodeStatusValidator("Status", status),
      ]);
      if (valError) {
        toast.error(valError);
        return false;
      }

      const str = await AppNetworkRest.updatePromoCode(args);

      if (str) {
        toast.success(str);
        return true;
      }

      return false;
    } catch (e) {
      console.log(e, "e");
      return false;
    }
  }
);

const deletePromoCode = createAsyncThunk(
  "promoCodes/deletePromoCode",
  async (args, { getState }) => {
    try {
      const { id } = args;

      const str = await AppNetworkRest.deletePromoCode(args);

      if (str) {
        toast.success(str);
        return true;
      }

      return false;
    } catch (e) {
      console.log(e, "e");
      return false;
    }
  }
);

export const promoCodesSlice = createSlice({
  name: "promoCodes",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getPromoCodes.fulfilled, (state, action) => {
      if (action.payload) {
        state.promoCodes = action.payload;
      }
    });
  },
});

export { createPromoCode, getPromoCodes, updatePromoCode, deletePromoCode };

export default promoCodesSlice.reducer;
