import localforage from "localforage";

function configDB() {
  localforage.config({
    driver: localforage.LOCALSTORAGE,
    name: "AppStorage",
  });
}

export { configDB };
