import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setSelectedRouteIndex } from "../controllers/appbar";
import { getLocale, updateLocale } from "../controllers/locales";

import CContainer from "../components/container";
import CLoader from "../components/loader";
import CButton from "../components/button";

const localePaths = {
  "Web/English": "web/en_US.json",
  "Web/Swedish": "web/sv_SE.json",
  "Mobile/English": "mobile/en_US.json",
  "Mobile/Swedish": "mobile/sv_SE.json",
  "Server/English": "server/en_US.json",
  "Server/Swedish": "server/sv_SE.json",
};

function SLocales() {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.locales.isLoading);
  const locale = useSelector((state) => state.locales.locale);

  const [localePath, setLocalePath] = useState(Object.keys(localePaths)[0]);
  const [localeText, setLocaleText] = useState(null);

  useEffect(() => {
    _init();
  }, []);

  useEffect(() => {
    setLocaleText(JSON.stringify(locale, undefined, 2));
  }, [locale]);

  const _init = () => {
    dispatch(setSelectedRouteIndex(8));
    dispatch(
      getLocale({
        path: localePaths[localePath],
      })
    );
  };

  return (
    <CContainer>
      {!localeText ? (
        <CLoader />
      ) : (
        <>
          <div className="flex flex-row items-center justify-center h-[10%] w-[100%] overflow-scroll">
            {Object.keys(localePaths).map((_) => (
              <CButton
                className="mr-[2%] bg-[#484F52]"
                text={_}
                onClick={() => {
                  setLocalePath(_);
                  dispatch(
                    getLocale({
                      path: localePaths[_],
                    })
                  );
                }}
              />
            ))}
          </div>
          <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[1%] overflow-scroll">
            <div className="flex flex-row justify-between items-center w-[100%]">
              <p className="text-[16px] font-[600]">Locale: {localePath}</p>
              <CButton
                className="mx-[2%]"
                text="Save"
                onClick={() => {
                  dispatch(
                    updateLocale({
                      locale_path: localePaths[localePath],
                      locale_text: localeText,
                    })
                  );
                }}
              />
            </div>
            <div className="h-[2%]" />
            <textarea
              className="textarea textarea-primary textarea-md text-[16px] h-[100%] w-[100%]"
              onChange={(e) => setLocaleText(e.target.value)}
              value={localeText}
            />
          </div>
        </>
      )}
    </CContainer>
  );
}

export default SLocales;
