import localforage from "localforage";

class AppResponse {
  success;
  data;
  error;
  errorMessage;

  constructor(props) {
    Object.assign(this, props);
  }

  static failure({ errorMessage = "Something went off the rails." }) {
    const obj = {
      success: false,
      errorMessage: errorMessage,
    };

    return new AppResponse(obj);
  }

  static parse(map) {
    const obj = {
      success: map["success"] ?? false,
      data: map["data"],
      error: map["error"],
      errorMessage: map["error_message"],
    };

    return new AppResponse(obj);
  }
}

class UserRoles {
  static user = "user";
  static admin = "admin";
}

class AppUser {
  id;
  email;
  uuid;
  token;
  role;
  createdAt;
  updatedAt;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map) {
    const obj = {
      id: map["id"],
      email: map["email"],
      uuid: map["uuid"],
      token: map["token"],
      role: UserRoles.admin,
      createdAt: map["created_at"],
      updatedAt: map["updated_at"],
    };

    return new AppUser(obj);
  }

  cache = async () => {
    await localforage.setItem("appUser", this);
  };
}

class Dashboard {
  users;
  activeSubscriptions;
  cancelledSubscriptions;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map) {
    const obj = {
      users: map["users"],
      activeSubscriptions: map["active_subscriptions"],
      cancelledSubscriptions: map["cancelled_subscriptions"],
    };

    return new Dashboard(obj);
  }
}

class User {
  id;
  email;
  phone;
  uuid;
  isCarConnected;
  isLocationConnected;
  isSubscribed;
  isActive;
  createdAt;
  updatedAt;

  subscription;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map) {
    const obj = {
      id: map["id"],
      email: map["email"],
      phone: map["phone"],
      uuid: map["uuid"],
      isCarConnected: map["is_car_connected"],
      isLocationConnected: map["is_location_connected"],
      isSubscribed: map["is_subscribed"],
      isActive: map["is_active"],
      createdAt: map["created_at"],
      updatedAt: map["updated_at"],

      subscription:
        map["active_subscription"] != null
          ? Subscription.parse(map["active_subscription"])
          : null,
    };

    return new User(obj);
  }
}

class Subscription {
  id;
  title;
  expiry;
  status;
  paymentMethod;
  createdAt;
  updatedAt;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map) {
    const obj = {
      id: map["id"],
      title: map["title"],
      expiry: map["expiry"],
      status: map["status"],
      paymentMethod: map["payment_method"],
      createdAt: map["created_at"],
      updatedAt: map["updated_at"],
    };

    return new Subscription(obj);
  }
}

class SubscriptionLog {
  id;
  title;
  status;
  paymentMethod;
  months;
  createdAt;
  updatedAt;

  user;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map) {
    const obj = {
      id: map["id"],
      title: map["title"],
      status: map["status"],
      paymentMethod: map["payment_method"],
      months: map["months"],
      createdAt: map["created_at"],
      updatedAt: map["updated_at"],

      user: map["user"] != null ? User.parse(map["user"]) : null,
    };

    return new SubscriptionLog(obj);
  }
}

class PaymentPlan {
  id;
  title;
  originalPrice;
  discountedPrice;
  months;
  isBestValue;
  freeMonths;
  createdAt;
  updatedAt;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map) {
    const obj = {
      id: map["id"],
      title: map["title"],
      originalPrice: map["original_price"],
      discountedPrice: map["discounted_price"],
      months: map["months"],
      isBestValue: map["is_best_value"],
      freeMonths: map["free_months"],
      createdAt: map["created_at"],
      updatedAt: map["updated_at"],
    };

    return new PaymentPlan(obj);
  }
}

class PromoCode {
  id;
  title;
  discount;
  status;
  createdAt;
  updatedAt;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map) {
    const obj = {
      id: map["id"],
      title: map["title"],
      discount: map["discount"],
      status: map["status"],
      createdAt: map["created_at"] ? Date.parse(map["created_at"]) : null,
      updatedAt: map["updated_at"] ? Date.parse(map["updated_at"]) : null,
    };

    return new PromoCode(obj);
  }
}

class FAQ {
  id;
  title;
  description;
  locale;
  createdAt;
  updatedAt;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map) {
    const obj = {
      id: map["id"],
      title: map["title"],
      description: map["description"],
      locale: map["locale"],
      createdAt: map["created_at"],
      updatedAt: map["updated_at"],
    };

    return new FAQ(obj);
  }
}

class ContactSupport {
  id;
  name;
  email;
  message;
  createdAt;
  updatedAt;

  constructor(props) {
    Object.assign(this, props);
  }

  static parse(map) {
    const obj = {
      id: map["id"],
      name: map["name"],
      email: map["email"],
      message: map["message"],
      createdAt: map["created_at"],
      updatedAt: map["updated_at"],
    };

    return new ContactSupport(obj);
  }
}

export {
  AppResponse,
  AppUser,
  Dashboard,
  User,
  Subscription,
  SubscriptionLog,
  PaymentPlan,
  PromoCode,
  FAQ,
  ContactSupport,
};
