import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

import * as AppNetworkRest from "../utils/network_rest";
import * as AppRoutes from "../utils/routes";
import localforage from "localforage";

const {
  nullValidator,
  emailValidator,
  validate,
} = require("../utils/validators");

const initialState = {
  router: AppRoutes.authRouter,
  isLoading: false,
};

const login = createAsyncThunk("auth/login", async (args) => {
  const { email, password } = args;

  const valError = validate([
    () => emailValidator("Email", email),
    () => nullValidator("Password", password),
  ]);
  if (valError) {
    toast.error(valError);
    return;
  }

  const appUser = await AppNetworkRest.login(args);

  if (appUser) {
    await appUser.cache();
    toast.success("Logged in.");
  }

  return appUser;
});

const sync = createAsyncThunk("auth/sync", async (args) => {
  const appUser = await AppNetworkRest.sync(args);

  if (appUser) {
    await appUser.cache();
  }

  return appUser;
});

const logout = createAsyncThunk("auth/logout", async (args) => {
  await localforage.removeItem("appUser");
  return;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setRouter: (state, action) => {
      state.router = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.router = AppRoutes.userRouter;
      }
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      state.router = AppRoutes.authRouter;
      window.location = "/";
    });
  },
});

export const { setRouter } = authSlice.actions;

export { login, sync, logout };

export default authSlice.reducer;
