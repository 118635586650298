import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RouterProvider } from "react-router-dom";
import localforage from "localforage";

import { configDB } from "../utils/globals";
import { sync } from "../controllers/auth";
import { setRouter } from "../controllers/auth";
import { authRouter, userRouter } from "../utils/routes";

async function getRouter() {
  let appUser = await localforage.getItem("appUser");
  if (!appUser) {
    return authRouter;
  } else {
    return userRouter;
  }
}

function SMain() {
  const router = useSelector((state) => state.auth.router);

  const dispatch = useDispatch();

  useEffect(() => {
    configDB();
    syncAppUser();
    init();
  }, []);

  const syncAppUser = async () => {
    if (router == userRouter) {
      let appUser = await localforage.getItem("appUser");
      if (appUser) {
        dispatch(sync(appUser));
      }
    }
  };

  const init = async () => {
    const _router = await getRouter();
    dispatch(setRouter(_router));
  };

  return router ? <RouterProvider router={router} /> : <></>;
}

export default SMain;
