import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setSelectedRouteIndex } from "../controllers/appbar";
import {
  createPromoCode,
  getPromoCodes,
  updatePromoCode,
  deletePromoCode,
} from "../controllers/promo_codes";

import CContainer from "../components/container";
import CLoader from "../components/loader";
import CButton from "../components/button";
import CInput from "../components/input";

import EditSVG from "../assets/images/edit.svg";
import DeleteSVG from "../assets/images/delete.svg";

import { promoCodeStatuses } from "../utils/constants";

function SPromoCodes() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector((state) => state.promoCodes.isLoading);
  const promoCodes = useSelector((state) => state.promoCodes.promoCodes);
  const [promoCode, setPromoCode] = useState(null);

  useEffect(() => {
    _init();
  }, []);

  const _init = () => {
    dispatch(setSelectedRouteIndex(5));
    dispatch(getPromoCodes());
  };

  return (
    <CContainer>
      {!promoCodes ? (
        <CLoader />
      ) : (
        <>
          <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[1%] overflow-scroll">
            <div className="overflow-x-auto">
              <table className="table table-sm table-pin-rows">
                <thead className="text-[16px]">
                  <tr>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Discount</th>
                    <th>Active</th>
                    <th>Created at</th>
                    <th>Updated at</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {promoCodes.map((_, index) => (
                    <CPromoCode
                      promoCode={promoCodes[index]}
                      setPromoCode={setPromoCode}
                    />
                  ))}
                </tbody>
              </table>
            </div>
            <MPromoCode promoCode={promoCode} />
            <MDeletePromoCode promoCode={promoCode} />
          </div>
        </>
      )}
    </CContainer>
  );
}

function CPromoCode({ promoCode, setPromoCode }) {
  return (
    <tr key={promoCode.id}>
      <th className="text-[16px]">{promoCode.id ?? "N/A"}</th>
      <th className="text-[16px]">{promoCode.title ?? "N/A"}</th>
      <td className="text-[16px]">{promoCode.discount ?? "N/A"}</td>
      <td className="text-[16px]">
        {promoCode.status == promoCodeStatuses.active ? "Yes" : "No"}
      </td>
      <td className="text-[16px]">
        {new Intl.DateTimeFormat("en-US", {
          dateStyle: "long",
        }).format(new Date(promoCode.createdAt))}
      </td>
      <td className="text-[16px]">
        {new Intl.DateTimeFormat("en-US", {
          dateStyle: "long",
        }).format(new Date(promoCode.updatedAt))}
      </td>
      <td>
        <div className="flex justify-start items-center h-[20px] w-[20px]">
          <img
            src={EditSVG}
            className="h-[20px] w-[20px] cursor-pointer"
            onClick={() => {
              setPromoCode(promoCode);
              window.modal_promo_code.showModal();
            }}
          />
        </div>
      </td>
      <td>
        <div className="flex justify-start items-center h-[20px] w-[20px]">
          <img
            src={DeleteSVG}
            className="h-[20px] w-[20px] cursor-pointer"
            onClick={() => {
              setPromoCode(promoCode);
              window.modal_delete_promo_code.showModal();
            }}
          />
        </div>
      </td>
    </tr>
  );
}

const MPromoCode = ({ promoCode }) => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [active, setActive] = useState(false);

  useEffect(() => {
    setTitle(promoCode?.title ?? "");
    setActive(promoCode?.status == promoCodeStatuses.active ? true : false);
  }, [promoCode]);

  return (
    <>
      <dialog id="modal_promo_code" className="modal">
        <form method="dialog" className="modal-box bg-white">
          <div className="flex flex-col items-start justify-center bg-white p-[2%] w-[100%]">
            <CInput
              label="Title"
              type="text"
              className="w-[100%]"
              placeholder="Enter the title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <div className="h-[20px]" />
            <div className="h-[20px]" />
            <div className="flex flex-row items-center justify-between  w-[100%]">
              <p className="text-[16px] font-[800]">Active</p>
              <input
                type="checkbox"
                className="toggle toggle-accent"
                checked={active}
                onChange={(e) => setActive(e.target.checked)}
              />
            </div>
            <div className="h-[20px]" />
            <CButton
              className="w-[50%] self-center"
              text="Save"
              onClick={async () => {
                let res = await dispatch(
                  updatePromoCode({
                    id: promoCode.id,
                    title: title,
                    status: active
                      ? promoCodeStatuses.active
                      : promoCodeStatuses.unactive,
                  })
                );

                if (res.payload) {
                  dispatch(getPromoCodes());
                }
                window.modal_promo_code.close();
              }}
            />
          </div>
        </form>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </>
  );
};

const MDeletePromoCode = ({ promoCode }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <dialog id="modal_delete_promo_code" className="modal">
        <form method="dialog" className="modal-box bg-white">
          <div className="flex flex-col items-center justify-center bg-white">
            <p className="text-[18px] font-[800]">Are you sure?</p>
            <div className="h-[20px]" />
            <p className="text-[14px] font-[600]">
              This promo code will be deleted.
            </p>
            <div className="h-[30px]" />
            <CButton
              className="w-[20hvw]"
              text="Cancel"
              onClick={() => {
                window.modal_delete_promo_code.close();
              }}
            />
            <div className="h-[10px]" />
            <p
              className="text-[14px] font-[600] text-[#E53935] cursor-pointer"
              onClick={async () => {
                window.modal_delete_promo_code.close();
                let res = await dispatch(
                  deletePromoCode({
                    id: promoCode.id,
                  })
                );

                if (res.payload) {
                  dispatch(getPromoCodes());
                }
              }}
            >
              Confirm
            </p>
            <div className="h-[10px]" />
          </div>
        </form>
        <form method="dialog" className="modal-backdrop">
          <button>Close</button>
        </form>
      </dialog>
    </>
  );
};

export default SPromoCodes;
