import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setSelectedRouteIndex } from "../controllers/appbar";
import { getUserSubscriptions } from "../controllers/user_subscriptions";

import CContainer from "../components/container";
import CLoader from "../components/loader";

function SUserSubscriptions() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector((state) => state.userSubscriptions.isLoading);
  const userSubscriptions = useSelector(
    (state) => state.userSubscriptions.userSubscriptions
  );

  useEffect(() => {
    _init();
  }, []);

  const _init = () => {
    dispatch(setSelectedRouteIndex(2));
    dispatch(getUserSubscriptions());
  };

  return (
    <CContainer>
      {!userSubscriptions ? (
        <CLoader />
      ) : (
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[1%] overflow-scroll">
          <div className="overflow-x-auto">
            <table className="table table-sm table-pin-rows">
              <thead className="text-[16px]">
                <tr>
                  <th>ID</th>
                  <th>User ID</th>
                  <th>User Email</th>
                  <th>User Phone</th>
                  <th>Title</th>
                  <th>Payment Method</th>
                  <th>Status</th>
                  <th>Expiry</th>
                  <th>Created at</th>
                  <th>Updated at</th>
                </tr>
              </thead>
              <tbody>
                {userSubscriptions.map((_, index) => (
                  <CUserSubscription
                    userSubscription={userSubscriptions[index]}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </CContainer>
  );
}

function CUserSubscription({ userSubscription }) {
  return (
    <tr key={userSubscription.subscription.id}>
      <th className="text-[16px]">
        {userSubscription.subscription.id ?? "N/A"}
      </th>
      <th className="text-[16px]">{userSubscription.id ?? "N/A"}</th>
      <td className="text-[16px]">{userSubscription.email ?? "N/A"}</td>
      <td className="text-[16px]">
        {userSubscription.phone ? `+46${userSubscription.phone}` : "N/A"}
      </td>
      <td className="text-[16px]">
        {userSubscription.subscription.title ?? "N/A"}
      </td>
      <td className="text-[16px]">
        {userSubscription.subscription.paymentMethod ?? "N/A"}
      </td>
      <td className="text-[16px]">
        {userSubscription.subscription.status ?? "N/A"}
      </td>
      <td className="text-[16px]">
        {new Intl.DateTimeFormat("en-US", {
          dateStyle: "long",
        }).format(new Date(userSubscription.subscription.expiry))}
      </td>
      <td className="text-[16px]">
        {new Intl.DateTimeFormat("en-US", {
          dateStyle: "long",
        }).format(new Date(userSubscription.subscription.createdAt))}
      </td>
      <td className="text-[16px]">
        {new Intl.DateTimeFormat("en-US", {
          dateStyle: "long",
        }).format(new Date(userSubscription.subscription.updatedAt))}
      </td>
    </tr>
  );
}

export default SUserSubscriptions;
