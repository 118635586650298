import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import Toaster from "./components/toaster";

import "./css/index.css";

import SMain from "./screens/main";

import { store } from "./controllers/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Toaster />
    <React.StrictMode>
      <SMain />
    </React.StrictMode>
  </Provider>
);
