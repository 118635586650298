function CButton({ text, onClick, isLoading, className, style }) {
  return (
    <button
      className={`bg-primary text-white rounded-[20px] px-[10px] py-[10px] ${className}`}
      onClick={!isLoading ? onClick : null}
      style={style}
    >
      {!isLoading ? (
        text
      ) : (
        <div className="loading loading-spinner loading-sm"></div>
      )}
    </button>
  );
}

export default CButton;
