import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as AppNetworkRest from "../utils/network_rest";
import { nullValidator, numberValidator, validate } from "../utils/validators";
import toast from "react-hot-toast";

const initialState = {
  paymentPlans: [],
};

const createPaymentPlan = createAsyncThunk(
  "paymentPlans/createPaymentPlan",
  async (args, { getState }) => {
    try {
      const {
        title,
        original_price,
        discounted_price,
        months,
        free_months,
        is_best_value,
      } = args;

      const valError = validate([
        () => nullValidator("Title", title),
        () => numberValidator("Original price", original_price, 10000),
        () => numberValidator("Discounted price", discounted_price, 10000),
        () => numberValidator("Months", months, 100),
        () => numberValidator("Free months", free_months, 100),
        () => nullValidator("Best value", is_best_value),
      ]);
      if (valError) {
        toast.error(valError);
        return false;
      }

      const str = await AppNetworkRest.createPaymentPlan(args);

      if (str) {
        toast.success(str);
        return true;
      }

      return false;
    } catch (e) {
      console.log(e, "e");
      return false;
    }
  }
);

const getPaymentPlans = createAsyncThunk(
  "paymentPlans/getPaymentPlans",
  async (args, { getState }) => {
    try {
      const paymentPlans = await AppNetworkRest.getPaymentPlans();
      return paymentPlans;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

const updatePaymentPlan = createAsyncThunk(
  "paymentPlans/updatePaymentPlan",
  async (args, { getState }) => {
    try {
      const { id, title, is_best_value } = args;

      const valError = validate([
        () => nullValidator("Title", title),
        () => nullValidator("Best value", is_best_value),
      ]);
      if (valError) {
        toast.error(valError);
        return false;
      }

      const str = await AppNetworkRest.updatePaymentPlan(args);

      if (str) {
        toast.success(str);
        return true;
      }

      return false;
    } catch (e) {
      console.log(e, "e");
      return false;
    }
  }
);

const deletePaymentPlan = createAsyncThunk(
  "paymentPlans/deletePaymentPlan",
  async (args, { getState }) => {
    try {
      const { id } = args;

      const str = await AppNetworkRest.deletePaymentPlan(args);

      if (str) {
        toast.success(str);
        return true;
      }

      return false;
    } catch (e) {
      console.log(e, "e");
      return false;
    }
  }
);

export const paymentPlansSlice = createSlice({
  name: "paymentPlans",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getPaymentPlans.fulfilled, (state, action) => {
      if (action.payload) {
        state.paymentPlans = action.payload;
      }
    });
  },
});

export {
  createPaymentPlan,
  getPaymentPlans,
  updatePaymentPlan,
  deletePaymentPlan,
};

export default paymentPlansSlice.reducer;
