import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedRouteIndex: 0,
};

export const appBarSlice = createSlice({
  name: "appBar",
  initialState,
  reducers: {
    setSelectedRouteIndex: (state, action) => {
      state.selectedRouteIndex = action.payload;
    },
  },
});

export const { setSelectedRouteIndex } = appBarSlice.actions;

export default appBarSlice.reducer;
