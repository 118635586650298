import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setSelectedRouteIndex } from "../controllers/appbar";
import { getContactSupports } from "../controllers/contact_supports";

import CContainer from "../components/container";
import CLoader from "../components/loader";

function SContactSupports() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector((state) => state.contactSupports.isLoading);
  const contactSupports = useSelector(
    (state) => state.contactSupports.contactSupports
  );

  useEffect(() => {
    _init();
  }, []);

  const _init = () => {
    dispatch(setSelectedRouteIndex(7));
    dispatch(getContactSupports());
  };

  return (
    <CContainer>
      {!contactSupports ? (
        <CLoader />
      ) : (
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[1%] overflow-scroll">
          <div className="overflow-x-auto">
            <table className="table table-sm table-pin-rows">
              <thead className="text-[16px]">
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Message</th>
                  <th>Created at</th>
                  <th>Updated at</th>
                </tr>
              </thead>
              <tbody>
                {contactSupports.map((_, index) => (
                  <CContactSupport contactSupport={contactSupports[index]} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </CContainer>
  );
}

function CContactSupport({ contactSupport }) {
  return (
    <tr key={contactSupport.id}>
      <th className="text-[16px]">{contactSupport.id ?? "N/A"}</th>
      <th className="text-[16px]">{contactSupport.name ?? "N/A"}</th>
      <th className="text-[16px]">{contactSupport.email ?? "N/A"}</th>
      <td className="text-[16px] w-[30dvw]">
        {contactSupport.message ?? "N/A"}
      </td>
      <td className="text-[16px]">
        {new Intl.DateTimeFormat("en-US", {
          dateStyle: "long",
        }).format(new Date(contactSupport.createdAt))}
      </td>
      <td className="text-[16px]">
        {new Intl.DateTimeFormat("en-US", {
          dateStyle: "long",
        }).format(new Date(contactSupport.updatedAt))}
      </td>
    </tr>
  );
}

export default SContactSupports;
