import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

import * as AppNetworkRest from "../utils/network_rest";

const initialState = {
  isLoading: false,
  dashboard: null,
};

const getDashboard = createAsyncThunk(
  "dashboard/getDashboard",
  async (args, { getState }) => {
    try {
      const dashboard = await AppNetworkRest.getDashboard();
      return dashboard;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getDashboard.fulfilled, (state, action) => {
      if (action.payload) {
        state.dashboard = action.payload;
      }
    });
  },
});

export { getDashboard };

export default dashboardSlice.reducer;
