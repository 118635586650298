import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setSelectedRouteIndex } from "../controllers/appbar";
import { getUsers, updateUser } from "../controllers/users";

import CContainer from "../components/container";
import CLoader from "../components/loader";
import CButton from "../components/button";

import EditSVG from "../assets/images/edit.svg";
import DeleteSVG from "../assets/images/delete.svg";

function SDashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector((state) => state.users.isLoading);
  const users = useSelector((state) => state.users.users);
  const [user, setUser] = useState(null);

  useEffect(() => {
    _init();
  }, []);

  const _init = () => {
    dispatch(setSelectedRouteIndex(1));
    dispatch(getUsers());
  };

  return (
    <CContainer>
      {!users ? (
        <CLoader />
      ) : (
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[1%] overflow-scroll">
          <div className="overflow-x-auto">
            <table className="table table-sm table-pin-rows">
              <thead className="text-[16px]">
                <tr>
                  <th>ID</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>UUID</th>
                  <th>Car connected</th>
                  <th>Location connected</th>
                  <th>Subscribed</th>
                  <th>Active</th>
                  <th>Created at</th>
                  <th>Updated at</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {users.map((_, index) => (
                  <CUser user={users[index]} setUser={setUser} />
                ))}
              </tbody>
            </table>
          </div>
          <MEditUser user={user} />
        </div>
      )}
    </CContainer>
  );
}

function CUser({ user, setUser }) {
  return (
    <tr key={user.id}>
      <th className="text-[16px]">{user.id ?? "N/A"}</th>
      <td className="text-[16px]">{user.email ?? "N/A"}</td>
      <td className="text-[16px]">{user.phone ? `+46${user.phone}` : "N/A"}</td>
      <td className="text-[16px]">{user.uuid ?? "N/A"}</td>
      <td className="text-[16px]">{user.isCarConnected ? "Yes" : "No"}</td>
      <td className="text-[16px]">{user.isLocationConnected ? "Yes" : "No"}</td>
      <td className="text-[16px]">{user.isSubscribed ? "Yes" : "No"}</td>
      <td className="text-[16px]">{user.isActive ? "Yes" : "No"}</td>
      <td className="text-[16px]">
        {new Intl.DateTimeFormat("en-US", {
          dateStyle: "long",
        }).format(new Date(user.createdAt))}
      </td>
      <td className="text-[16px]">
        {new Intl.DateTimeFormat("en-US", {
          dateStyle: "long",
        }).format(new Date(user.updatedAt))}
      </td>
      <img
        src={EditSVG}
        className="h-[20px] w-[20px] sm:mt-[30%] mt-[60%] cursor-pointer"
        onClick={() => {
          setUser(user);
          window.modal_edit_user.showModal();
        }}
      />
    </tr>
  );
}

const MEditUser = ({ user }) => {
  const dispatch = useDispatch();

  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(user?.isActive ?? false);
  }, [user]);

  return (
    <>
      <dialog id="modal_edit_user" className="modal">
        <form method="dialog" className="modal-box bg-white">
          <div className="flex flex-col items-center justify-center bg-white p-[2%] w-[100%]">
            <div className="flex flex-row items-center justify-between  w-[100%]">
              <p className="text-[16px] font-[800]">Active</p>
              <input
                type="checkbox"
                className="toggle toggle-accent"
                checked={active}
                onChange={(e) => setActive(e.target.checked)}
              />
            </div>
            <div className="h-[20px]" />
            <CButton
              className="w-[50%]"
              text="Save"
              onClick={async () => {
                const res = await dispatch(
                  updateUser({
                    id: user.id,
                    is_active: active,
                  })
                );
                if (res.payload) {
                  dispatch(getUsers());
                }
                window.modal_edit_user.close();
              }}
            />
          </div>
        </form>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </>
  );
};

export default SDashboard;
