import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setSelectedRouteIndex } from "../controllers/appbar";
import { createFAQ, getFAQs, updateFAQ, deleteFAQ } from "../controllers/faqs";

import CContainer from "../components/container";
import CLoader from "../components/loader";
import CButton from "../components/button";
import CInput from "../components/input";

import EditSVG from "../assets/images/edit.svg";
import DeleteSVG from "../assets/images/delete.svg";

function SFAQs() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector((state) => state.faqs.isLoading);
  const faqs = useSelector((state) => state.faqs.faqs);
  const [faq, setFAQ] = useState(null);

  useEffect(() => {
    _init();
  }, []);

  const _init = () => {
    dispatch(setSelectedRouteIndex(6));
    dispatch(getFAQs());
  };

  return (
    <CContainer>
      {!faqs ? (
        <CLoader />
      ) : (
        <>
          <div className="flex flex-row items-center justify-center w-[100%]">
            <CButton
              className="sm:w-[10%] w-[40%]"
              text="Add"
              onClick={() => {
                setFAQ(null);
                window.modal_faq.showModal();
              }}
            />
          </div>
          <div className="flex flex-col items-start justify-start w-[100%] p-[1%] overflow-scroll">
            <div className="overflow-x-auto">
              <table className="table table-sm table-pin-rows">
                <thead className="text-[16px]">
                  <tr>
                    <th>ID</th>
                    <th>Question</th>
                    <th>Answer</th>
                    <th>Created at</th>
                    <th>Updated at</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {faqs.map((_, index) => (
                    <CFAQ faq={faqs[index]} setFAQ={setFAQ} />
                  ))}
                </tbody>
              </table>
            </div>
            <MFAQ faq={faq} />
            <MDeleteFAQ faq={faq} />
          </div>
        </>
      )}
    </CContainer>
  );
}

function CFAQ({ faq, setFAQ }) {
  return (
    <tr key={faq.id}>
      <th className="text-[16px]">{faq.id ?? "N/A"}</th>
      <th className="text-[16px]">{faq.title ?? "N/A"}</th>
      <td className="text-[16px] sm:w-[30dvw] w-[90dvw]">
        {faq.description ?? "N/A"}
      </td>
      <td className="text-[16px]">
        {new Intl.DateTimeFormat("en-US", {
          dateStyle: "long",
        }).format(new Date(faq.createdAt))}
      </td>
      <td className="text-[16px]">
        {new Intl.DateTimeFormat("en-US", {
          dateStyle: "long",
        }).format(new Date(faq.updatedAt))}
      </td>
      <td>
        <div className="flex justify-start items-center h-[20px] w-[20px]">
          <img
            src={EditSVG}
            className="h-[20px] w-[20px] cursor-pointer"
            onClick={() => {
              setFAQ(faq);
              window.modal_faq.showModal();
            }}
          />
        </div>
      </td>
      <td>
        <div className="flex justify-start items-center h-[20px] w-[20px]">
          <img
            src={DeleteSVG}
            className="h-[20px] w-[20px] cursor-pointer"
            onClick={() => {
              setFAQ(faq);
              window.modal_delete_faq.showModal();
            }}
          />
        </div>
      </td>
    </tr>
  );
}

const locales = {
  English: "en",
  Swedish: "sv",
};

const MFAQ = ({ faq }) => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [locale, setLocale] = useState("");

  useEffect(() => {
    setTitle(faq?.title ?? "");
    setDescription(faq?.description ?? "");
    if (faq?.locale) {
      const index = Object.values(locales).indexOf(faq?.locale);
      setLocale(Object.keys(locales)[index]);
    } else {
      setLocale(Object.keys(locales)[0]);
    }
  }, [faq]);

  return (
    <>
      <dialog id="modal_faq" className="modal">
        <form method="dialog" className="modal-box bg-white">
          <div className="flex flex-col items-start justify-center bg-white p-[2%] w-[100%]">
            <select
              className="select select-accent w-full max-w-xs"
              value={locale}
              onChange={(e) => {
                setLocale(e.target.value);
              }}
            >
              <option disabled selected>
                Select locale
              </option>
              {Object.keys(locales).map((v) => (
                <option value={v} className="p-[2%]">
                  {v}
                </option>
              ))}
            </select>
            <div className="h-[20px]" />
            <CInput
              label="Title"
              type="text"
              className="w-[100%]"
              placeholder="Enter the title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <div className="h-[20px]" />
            <CInput
              label="Description"
              type="text"
              className="w-[100%]"
              placeholder="Enter the description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <div className="h-[20px]" />
            <CButton
              className="w-[50%] self-center"
              text="Save"
              onClick={async () => {
                let res;

                const index = Object.keys(locales).indexOf(locale);
                const _locale = Object.values(locales)[index];

                if (!faq) {
                  res = await dispatch(
                    createFAQ({
                      title: title,
                      description: description,
                      locale: _locale,
                    })
                  );
                } else {
                  res = await dispatch(
                    updateFAQ({
                      id: faq.id,
                      title: title,
                      description: description,
                      locale: _locale,
                    })
                  );
                }

                if (res.payload) {
                  dispatch(getFAQs());
                }
                window.modal_faq.close();
              }}
            />
          </div>
        </form>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </>
  );
};

const MDeleteFAQ = ({ faq }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <dialog id="modal_delete_faq" className="modal">
        <form method="dialog" className="modal-box bg-white">
          <div className="flex flex-col items-center justify-center bg-white">
            <p className="text-[18px] font-[800]">Are you sure?</p>
            <div className="h-[20px]" />
            <p className="text-[14px] font-[600]">This FAQ will be deleted.</p>
            <div className="h-[30px]" />
            <CButton
              className="w-[20hvw]"
              text="Cancel"
              onClick={() => {
                window.modal_delete_faq.close();
              }}
            />
            <div className="h-[10px]" />
            <p
              className="text-[14px] font-[600] text-[#E53935] cursor-pointer"
              onClick={async () => {
                window.modal_delete_faq.close();
                let res = await dispatch(
                  deleteFAQ({
                    id: faq.id,
                  })
                );

                if (res.payload) {
                  dispatch(getFAQs());
                }
              }}
            >
              Confirm
            </p>
            <div className="h-[10px]" />
          </div>
        </form>
        <form method="dialog" className="modal-backdrop">
          <button>Close</button>
        </form>
      </dialog>
    </>
  );
};

export default SFAQs;
