import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as AppNetworkRest from "../utils/network_rest";

const initialState = {
  userSubscriptions: [],
};

const getUserSubscriptions = createAsyncThunk(
  "userSubscriptions/getUserSubscriptions",
  async (args, { getState }) => {
    try {
      const userSubscriptions = await AppNetworkRest.getUserSubscriptions();
      return userSubscriptions;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

export const userSubscriptionsSlice = createSlice({
  name: "userSubscriptions",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getUserSubscriptions.fulfilled, (state, action) => {
      if (action.payload) {
        state.userSubscriptions = action.payload;
      }
    });
  },
});

export { getUserSubscriptions };

export default userSubscriptionsSlice.reducer;
