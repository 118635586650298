import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setSelectedRouteIndex } from "../controllers/appbar";
import {
  createPaymentPlan,
  getPaymentPlans,
  updatePaymentPlan,
  deletePaymentPlan,
} from "../controllers/payment_plans";

import CContainer from "../components/container";
import CLoader from "../components/loader";
import CButton from "../components/button";
import CInput from "../components/input";

import EditSVG from "../assets/images/edit.svg";
import DeleteSVG from "../assets/images/delete.svg";

function SPaymentPlans() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector((state) => state.paymentPlans.isLoading);
  const paymentPlans = useSelector((state) => state.paymentPlans.paymentPlans);
  const [paymentPlan, setPaymentPlan] = useState(null);

  useEffect(() => {
    _init();
  }, []);

  const _init = () => {
    dispatch(setSelectedRouteIndex(4));
    dispatch(getPaymentPlans());
  };

  return (
    <CContainer>
      {!paymentPlans ? (
        <CLoader />
      ) : (
        <>
          <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[1%] overflow-scroll">
            <div className="overflow-x-auto">
              <table className="table table-sm table-pin-rows">
                <thead className="text-[16px]">
                  <tr>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Original Price</th>
                    <th>Discounted Price</th>
                    <th>Months</th>
                    <th>Free Months</th>
                    <th>Best Value</th>
                    <th>Created at</th>
                    <th>Updated at</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {paymentPlans.map((_, index) => (
                    <CPaymentPlan
                      paymentPlan={paymentPlans[index]}
                      setPaymentPlan={setPaymentPlan}
                    />
                  ))}
                </tbody>
              </table>
            </div>
            <MPaymentPlan paymentPlan={paymentPlan} />
            <MDeletePaymentPlan paymentPlan={paymentPlan} />
          </div>
        </>
      )}
    </CContainer>
  );
}

function CPaymentPlan({ paymentPlan, setPaymentPlan }) {
  return (
    <tr key={paymentPlan.id}>
      <th className="text-[16px]">{paymentPlan.id ?? "N/A"}</th>
      <th className="text-[16px]">{paymentPlan.title ?? "N/A"}</th>
      <td className="text-[16px]">{paymentPlan.originalPrice ?? "N/A"}</td>
      <td className="text-[16px]">{paymentPlan.discountedPrice ?? "N/A"}</td>
      <td className="text-[16px]">{paymentPlan.months ?? "N/A"}</td>
      <td className="text-[16px]">{paymentPlan.freeMonths ?? "N/A"}</td>
      <td className="text-[16px]">{paymentPlan.isBestValue ? "Yes" : "No"}</td>
      <td className="text-[16px]">
        {new Intl.DateTimeFormat("en-US", {
          dateStyle: "long",
        }).format(new Date(paymentPlan.createdAt))}
      </td>
      <td className="text-[16px]">
        {new Intl.DateTimeFormat("en-US", {
          dateStyle: "long",
        }).format(new Date(paymentPlan.updatedAt))}
      </td>
      <td>
        <div className="flex justify-start items-center h-[20px] w-[20px]">
          <img
            src={EditSVG}
            className="h-[20px] w-[20px] cursor-pointer"
            onClick={() => {
              setPaymentPlan(paymentPlan);
              window.modal_payment_plan.showModal();
            }}
          />
        </div>
      </td>
      <td>
        <div className="flex justify-start items-center h-[20px] w-[20px]">
          <img
            src={DeleteSVG}
            className="h-[20px] w-[20px] cursor-pointer"
            onClick={() => {
              setPaymentPlan(paymentPlan);
              window.modal_delete_payment_plan.showModal();
            }}
          />
        </div>
      </td>
    </tr>
  );
}

const MPaymentPlan = ({ paymentPlan }) => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [isBestValue, setIsBestValue] = useState(false);

  useEffect(() => {
    setTitle(paymentPlan?.title ?? "");
    setIsBestValue(paymentPlan?.isBestValue ?? false);
  }, [paymentPlan]);

  return (
    <>
      <dialog id="modal_payment_plan" className="modal">
        <form method="dialog" className="modal-box bg-white">
          <div className="flex flex-col items-start justify-center bg-white p-[2%] w-[100%]">
            <CInput
              label="Title"
              type="text"
              className="w-[100%]"
              placeholder="Enter the title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <div className="h-[20px]" />
            <div className="flex flex-row items-center justify-between  w-[100%]">
              <p className="text-[16px] font-[800]">Best Value</p>
              <input
                type="checkbox"
                className="toggle toggle-accent"
                checked={isBestValue}
                onChange={(e) => setIsBestValue(e.target.checked)}
              />
            </div>
            <div className="h-[20px]" />
            <CButton
              className="w-[50%] self-center"
              text="Save"
              onClick={async () => {
                let res;

                res = await dispatch(
                  updatePaymentPlan({
                    id: paymentPlan.id,
                    title: title,
                    is_best_value: isBestValue,
                  })
                );

                if (res.payload) {
                  dispatch(getPaymentPlans());
                }
                window.modal_payment_plan.close();
              }}
            />
          </div>
        </form>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </>
  );
};

const MDeletePaymentPlan = ({ paymentPlan }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <dialog id="modal_delete_payment_plan" className="modal">
        <form method="dialog" className="modal-box bg-white">
          <div className="flex flex-col items-center justify-center bg-white">
            <p className="text-[18px] font-[800]">Are you sure?</p>
            <div className="h-[20px]" />
            <p className="text-[14px] font-[600]">
              This payment plan will be deleted.
            </p>
            <div className="h-[30px]" />
            <CButton
              className="w-[20hvw]"
              text="Cancel"
              onClick={() => {
                window.modal_delete_payment_plan.close();
              }}
            />
            <div className="h-[10px]" />
            <p
              className="text-[14px] font-[600] text-[#E53935] cursor-pointer"
              onClick={async () => {
                window.modal_delete_payment_plan.close();
                let res = await dispatch(
                  deletePaymentPlan({
                    id: paymentPlan.id,
                  })
                );

                if (res.payload) {
                  dispatch(getPaymentPlans());
                }
              }}
            >
              Confirm
            </p>
            <div className="h-[10px]" />
          </div>
        </form>
        <form method="dialog" className="modal-backdrop">
          <button>Close</button>
        </form>
      </dialog>
    </>
  );
};

export default SPaymentPlans;
