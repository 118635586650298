import { useEffect } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { login } from "../controllers/auth";

import CInput from "../components/input";
import CButton from "../components/button";

function SLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector((state) => state.auth.isLoading);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onClick = async () => {
    const res = await dispatch(
      login({
        email,
        password,
      })
    );
  };

  return (
    <div className="flex flex-col items-center justify-center h-[100dvh] w-[100dvw]">
      <div className="flex flex-col md:h-[75dvh] md:w-[45dvw] sm:h-[80dvh] sm:w-[60dvw] h-[100dvh] w-[100dvw] bg-container-background p-[2%] overflow-x-scroll sm:rounded-[10px] rounded-none">
        <p className="text-[18px] font-[700] self-center">Login</p>
        <div className="flex flex-col justify-start h-[100%] w-[100%]">
          <div className="h-[2%]" />
          <CInput
            label="Email"
            type="email"
            className="mb-[14px] mt-[5px]"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="h-[2%]" />
          <CInput
            label="Password"
            type="password"
            className="mb-[14px] mt-[5px]"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => {
              if (e.keyCode == 13) {
                onClick();
              }
            }}
          />
          <div className="relative h-[100%] w-[100%]">
            <div className="absolute bottom-0 h-[100%] w-[100%] flex flex-col items-center justify-end">
              <CButton
                className="my-[14px] w-[100%]"
                text="Continue"
                onClick={onClick}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SLogin;
