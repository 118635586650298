import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setSelectedRouteIndex } from "../controllers/appbar";
import { getSubscriptionLogs } from "../controllers/subscription_logs";

import CContainer from "../components/container";
import CLoader from "../components/loader";

function SSubscriptionLogs() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector((state) => state.userSubscriptions.isLoading);
  const subscriptionLogs = useSelector(
    (state) => state.subscriptionLogs.subscriptionLogs
  );

  useEffect(() => {
    _init();
  }, []);

  const _init = () => {
    dispatch(setSelectedRouteIndex(3));
    dispatch(getSubscriptionLogs());
  };

  return (
    <CContainer>
      {!subscriptionLogs ? (
        <CLoader />
      ) : (
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[1%] overflow-scroll">
          <div className="overflow-x-auto">
            <table className="table table-sm table-pin-rows">
              <thead className="text-[16px]">
                <tr>
                  <th>ID</th>
                  <th>User ID</th>
                  <th>User Email</th>
                  <th>User Phone</th>
                  <th>Title</th>
                  <th>Payment Method</th>
                  <th>months</th>
                  <th>Status</th>
                  <th>Created at</th>
                  <th>Updated at</th>
                </tr>
              </thead>
              <tbody>
                {subscriptionLogs.map((_, index) => (
                  <CSubscriptionLog subscriptionLog={subscriptionLogs[index]} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </CContainer>
  );
}

function CSubscriptionLog({ subscriptionLog }) {
  return (
    <tr key={subscriptionLog.id}>
      <th className="text-[16px]">{subscriptionLog.id ?? "N/A"}</th>
      <th className="text-[16px]">{subscriptionLog.user.id ?? "N/A"}</th>
      <td className="text-[16px]">{subscriptionLog.user.email ?? "N/A"}</td>
      <td className="text-[16px]">
        {subscriptionLog.user.phone
          ? `+46${subscriptionLog.user.phone}`
          : "N/A"}
      </td>
      <td className="text-[16px]">{subscriptionLog.title ?? "N/A"}</td>
      <td className="text-[16px]">{subscriptionLog.paymentMethod ?? "N/A"}</td>
      <td className="text-[16px]">{subscriptionLog.months ?? "N/A"}</td>
      <td className="text-[16px]">{subscriptionLog.status ?? "N/A"}</td>
      <td className="text-[16px]">
        {new Intl.DateTimeFormat("en-US", {
          dateStyle: "long",
        }).format(new Date(subscriptionLog.createdAt))}
      </td>
      <td className="text-[16px]">
        {new Intl.DateTimeFormat("en-US", {
          dateStyle: "long",
        }).format(new Date(subscriptionLog.updatedAt))}
      </td>
    </tr>
  );
}

export default SSubscriptionLogs;
