import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as AppNetworkRest from "../utils/network_rest";
import { nullValidator, validate } from "../utils/validators";
import toast from "react-hot-toast";

const initialState = {
  faqs: [],
};

const createFAQ = createAsyncThunk(
  "faqs/createFAQ",
  async (args, { getState }) => {
    try {
      const { title, description, locale } = args;

      const valError = validate([
        () => nullValidator("Title", title),
        () => nullValidator("Description", description),
        () => nullValidator("Locale", locale),
      ]);
      if (valError) {
        toast.error(valError);
        return false;
      }

      const str = await AppNetworkRest.createFAQ(args);

      if (str) {
        toast.success(str);
        return true;
      }

      return false;
    } catch (e) {
      console.log(e, "e");
      return false;
    }
  }
);

const getFAQs = createAsyncThunk("faqs/getFAQs", async (args, { getState }) => {
  try {
    const faqs = await AppNetworkRest.getFAQs();
    return faqs;
  } catch (e) {
    console.log(e, "e");
  }
});

const updateFAQ = createAsyncThunk(
  "faqs/updateFAQ",
  async (args, { getState }) => {
    try {
      const { id, title, description, locale } = args;

      const valError = validate([
        () => nullValidator("Title", title),
        () => nullValidator("Description", description),
        () => nullValidator("Locale", locale),
      ]);
      if (valError) {
        toast.error(valError);
        return false;
      }

      const str = await AppNetworkRest.updateFAQ(args);

      if (str) {
        toast.success(str);
        return true;
      }

      return false;
    } catch (e) {
      console.log(e, "e");
      return false;
    }
  }
);

const deleteFAQ = createAsyncThunk(
  "faqs/deleteFAQ",
  async (args, { getState }) => {
    try {
      const { id } = args;

      const str = await AppNetworkRest.deleteFAQ(args);

      if (str) {
        toast.success(str);
        return true;
      }

      return false;
    } catch (e) {
      console.log(e, "e");
      return false;
    }
  }
);

export const faqsSlice = createSlice({
  name: "faqs",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getFAQs.fulfilled, (state, action) => {
      if (action.payload) {
        state.faqs = action.payload;
      }
    });
  },
});

export { createFAQ, getFAQs, updateFAQ, deleteFAQ };

export default faqsSlice.reducer;
