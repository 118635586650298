import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as AppNetworkRest from "../utils/network_rest";

const initialState = {
  subscriptionLogs: [],
};

const getSubscriptionLogs = createAsyncThunk(
  "subscriptionLogs/getSubscriptionLogs",
  async (args, { getState }) => {
    try {
      const subscriptionLogs = await AppNetworkRest.getSubscriptionLogs();
      return subscriptionLogs;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

export const subscriptionLogsSlice = createSlice({
  name: "subscriptionLogs",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getSubscriptionLogs.fulfilled, (state, action) => {
      if (action.payload) {
        state.subscriptionLogs = action.payload;
      }
    });
  },
});

export { getSubscriptionLogs };

export default subscriptionLogsSlice.reducer;
