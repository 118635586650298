import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { setSelectedRouteIndex } from "../controllers/appbar";
import { logout } from "../controllers/auth";

import MenuSVG from "../assets/images/menu.svg";
import CloseSVG from "../assets/images/close.svg";

import DashboardSVG from "../assets/images/a_dashboard.svg";
import UsersSVG from "../assets/images/a_users.svg";
import SubscriptionsSVG from "../assets/images/a_subscriptions.svg";
import SubscriptionLogsSVG from "../assets/images/a_subscription_logs.svg";
import PaymentPlansSVG from "../assets/images/a_payment_plans.svg";
import PromoCodesSVG from "../assets/images/a_promo_codes.svg";
import FAQsSVG from "../assets/images/a_faqs.svg";
import ContactSupportsSVG from "../assets/images/a_contact_supports.svg";
import LocalesSVG from "../assets/images/a_locales.svg";
import LogoutSVG from "../assets/images/a_logout.svg";

const icons = [
  {
    title: "Dashboard",
    route: "/dashboard",
    icon: DashboardSVG,
  },
  {
    title: "Users",
    route: "/users",
    icon: UsersSVG,
  },
  {
    title: "Subscriptions",
    route: "/subscriptions",
    icon: SubscriptionsSVG,
  },
  {
    title: "Subscription Logs",
    route: "/subscription_logs",
    icon: SubscriptionLogsSVG,
  },
  {
    title: "Payment Plans",
    route: "/payment_plans",
    icon: PaymentPlansSVG,
  },
  {
    title: "Promo Codes",
    route: "/promo_codes",
    icon: PromoCodesSVG,
  },
  {
    title: "FAQs",
    route: "/faqs",
    icon: FAQsSVG,
  },
  {
    title: "Contact Supports",
    route: "/contact_supports",
    icon: ContactSupportsSVG,
  },
  {
    title: "Locales",
    route: "/locales",
    icon: LocalesSVG,
  },
  {
    title: "Logout",
    route: "/logout",
    icon: LogoutSVG,
  },
];

function CAppbar({ className }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const selectedRouteIndex = useSelector(
    (state) => state.appBar.selectedRouteIndex
  );

  return (
    <div className="flex flex-row justify-center items-center h-[10%] w-[100%] bg-white relative top-0">
      <div className="drawer z-[1000]">
        <input
          id="my-drawer"
          type="checkbox"
          className="drawer-toggle"
          checked={isOpen}
        />
        <div className="drawer-content">
          <label htmlFor="my-drawer" className="drawer-button" onClick={toggle}>
            <img
              src={MenuSVG}
              className="cursor-pointer h-[30px] w-[30px] ml-[2%]"
              htmlFor="my-drawer"
            />
          </label>
        </div>
        <div className="drawer-side">
          <label
            htmlFor="my-drawer"
            className="drawer-overlay"
            onClick={toggle}
          ></label>
          <div className="flex flex-row justify-center items-start pl-[0.5%] z-[2000]">
            <img
              src={CloseSVG}
              className="cursor-pointer h-[40px] w-[40px] ml-[2%]"
              onClick={toggle}
            />
          </div>
          <ul className="menu p-4 pt-[30px] w-80 min-h-full bg-base-200 text-base-content">
            {icons.map((_, index) => (
              <CABListTile
                key={index}
                title={_.title}
                icon={_.icon}
                onClick={() => {
                  if (_.route == "/logout") {
                    dispatch(logout());
                    return;
                  }
                  dispatch(setSelectedRouteIndex(index));
                  navigate(_.route);
                  toggle();
                }}
              />
            ))}
          </ul>
        </div>
      </div>
      <div className="absolute">
        <p className="text-[16px] font-[600]">
          {icons[selectedRouteIndex].title}
        </p>
      </div>
    </div>
  );
}

function CABListTile({ key, title, icon, isSelected, onClick }) {
  return (
    <>
      <div
        className="flex flex-row justify-center items-center py-[2%] cursor-pointer"
        onClick={onClick}
        key={key}
      >
        <img src={icon} className="h-[20px] w-[20px] mr-[10px]" />
        <p
          className={`text-[16px] font-[600] ${
            isSelected ? "text-primary" : "text-black"
          }`}
        >
          {title}
        </p>
      </div>
    </>
  );
}

export default CAppbar;
