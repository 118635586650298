import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as AppNetworkRest from "../utils/network_rest";
import { nullValidator, validate } from "../utils/validators";
import toast from "react-hot-toast";

const initialState = {
  users: [],
};

const getUsers = createAsyncThunk(
  "users/getUsers",
  async (args, { getState }) => {
    try {
      const users = await AppNetworkRest.getUsers();
      return users;
    } catch (e) {
      console.log(e, "e");
    }
  }
);

const updateUser = createAsyncThunk(
  "users/updateUser",
  async (args, { getState }) => {
    try {
      const { id, is_active } = args;

      const valError = validate([() => nullValidator("Active", is_active)]);
      if (valError) {
        toast.error(valError);
        return false;
      }

      const str = await AppNetworkRest.updateUser(args);

      if (str) {
        toast.success(str);
        return true;
      }

      return false;
    } catch (e) {
      console.log(e, "e");
      return false;
    }
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      if (action.payload) {
        state.users = action.payload;
      }
    });
  },
});

export { getUsers, updateUser };

export default usersSlice.reducer;
