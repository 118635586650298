import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setSelectedRouteIndex } from "../controllers/appbar";
import { getDashboard } from "../controllers/dashboard";

import CContainer from "../components/container";
import CLoader from "../components/loader";

function SDashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector((state) => state.dashboard.isLoading);
  const dashboard = useSelector((state) => state.dashboard.dashboard);

  useEffect(() => {
    _init();
  }, []);

  const _init = () => {
    dispatch(setSelectedRouteIndex(0));
    dispatch(getDashboard());
  };

  return (
    <CContainer>
      {!dashboard ? (
        <CLoader />
      ) : (
        <div className="flex flex-col items-start justify-start h-[100%] w-[100%] p-[1%] overflow-scroll">
          <div className="flex flex-row items-center justify-evenly h-[30%] w-[100%]">
            <CDashboard title="Users" description={dashboard.users} />
            <CDashboard
              title="Active Subscriptions"
              description={dashboard.activeSubscriptions}
            />
            <CDashboard
              title="Cancelled Subscriptions"
              description={dashboard.cancelledSubscriptions}
            />
          </div>
        </div>
      )}
    </CContainer>
  );
}

function CDashboard({ title, description }) {
  return (
    <div className="flex flex-col items-center justify-center h-[100%] w-[30%] bg-[#F3F3F3] rounded-[10px]">
      <p className="text-[16px] font-[800] text-center">{title}</p>
      <p className="text-[20px] font-[500] text-center">{description}</p>
    </div>
  );
}

export default SDashboard;
