import CAppbar from "./appbar";

function CContainer({ className, children }) {
  return (
    <div
      className={`flex flex-col items-start justify-start sm:h-[100dvh] h-[100dvh] w-[100dvw] bg-background ${
        className ?? ""
      }`}
    >
      <CAppbar className="z-[200]" />
      {children}
    </div>
  );
}

export default CContainer;
